import { compose, propOr, path, has } from 'ramda'
import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

import config from '../../../gatsby-config'

export default function withHelmet(selector) {
  return WrappedComponent => {
    return props => {
      const seo = compose(
        selector(['seoFields']),
        propOr({}, 'data')
      )(props)
      let slug = compose(
        selector(['fields', 'slug']),
        propOr({}, 'data')
      )(props)
      const ogImage = path(['ogImage', 'file', 'url'], seo)
      const jsonLd = compose(
        selector(['fields', 'jsonLd']),
        propOr({}, 'data')
      )(props)

      if (has('contentfulHomepage', props.data)) {
        slug = ''
      }

      return (
        <Fragment>
          {seo && (
            <Helmet>
              <title>{seo.pageTitle}</title>
              <meta name="title" content={seo.title} />
              <meta name="description" content={seo.description.description} />

              <meta property="og:title" content={seo.title} />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={`${config.siteMetadata.siteUrl}/${slug}`}
              />
              <meta property="og:image" content={`https:${ogImage}`} />
              <meta
                property="og:description"
                content={seo.description.description}
              />
            </Helmet>
          )}
          <WrappedComponent {...props} />
          {jsonLd && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: jsonLd }}
            />
          )}
        </Fragment>
      )
    }
  }
}
