import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { compose } from 'ramda'
import marked from 'marked'
import ReactHtmlParser from 'react-html-parser'

import { contentfulSelector, repeat } from '../utils'

import Layout from '../components/layout'
import Button from '../components/Button/Button'
import withHelmet from '../components/hoc/withHelmet'

import bills from '../images/bills.svg'
import lightning from '../images/lightning.svg'
import shop from '../images/shop.svg'
import fees from '../images/fees.svg'
import blueCheck from '../images/check_blue.svg'
import orangeCheck from '../images/check_orange.svg'

import styles from './homepage.module.scss'
import inpowerCardImage from '../images/inpower_card.png'

const homepageSelector = contentfulSelector('contentfulHomepage')

const selectTitle = homepageSelector(repeat('title'))
const selectCardCallout = homepageSelector(repeat('cardCallout'))
const selectHeroImage = homepageSelector(['hero', 'file', 'url'])

const selectEasyCallouts = homepageSelector(['easyCallouts'])

const selectBannerImage = homepageSelector(['bannerImage', 'file', 'url'])
const selectBannerCaption = homepageSelector(['bannerCaption'])

const selectIconCalloutTitle = homepageSelector(repeat('iconCalloutTitle'))
const selectIconCallouts = homepageSelector(['iconCallouts'])

const selectAppAddTitle = homepageSelector(repeat('appAddTitle'))
const selectAppAddSubtitle = homepageSelector(['appAddSubtitle'])
const selectAppAddImage = homepageSelector(['appAddImage', 'file', 'url'])
const selectAppAddBullets = homepageSelector(['appAddBullets'])

const selectStoreAddTitle = homepageSelector(['storeAddTitle'])
const selectStoreAddDescription = homepageSelector(
  repeat('storeAddDescription')
)
const selectStoreAddImage = homepageSelector(['storeAddImage', 'file', 'url'])
const selectStoreAddBullets = homepageSelector(['storeAddBullets'])

const fromMarkup = compose(
  ReactHtmlParser,
  marked
)

const iconMap = { bills, lightning, shop, fees }

const selectFromMarkup = selector =>
  compose(
    fromMarkup,
    selector
  )

const IndexPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { appLink, payomaticLocationsLink }
    }
  } = data

  return (
    <Layout>
      <div
        className={styles.hero}
        style={{ backgroundImage: `url(${selectHeroImage(data)})` }}
      >
        <div className={styles.heroInfo}>
          <div className={styles.title}>
            {selectFromMarkup(selectTitle)(data)}
          </div>
          <Button
            className={styles.cta}
            label={
              <Fragment>
                Get Started
                <sup>2</sup>
              </Fragment>
            }
            href={payomaticLocationsLink}
          />
        </div>
      </div>
      {/* Card Callout Section */}
      <section className={styles.cardCalloutSection}>
        <div className={styles.inPowerCard}>
          <img src={inpowerCardImage} alt="inPower Card" />
        </div>
        <div className={styles.cardCallout}>
          {selectFromMarkup(selectCardCallout)(data)}
          <Button
            className={styles.cta}
            label={
              <Fragment>
                Get Started
                <sup>2</sup>
              </Fragment>
            }
            href={payomaticLocationsLink}
          />
        </div>
      </section>
      {/* Easy Callouts Section */}
      <section className={styles.easyCallouts}>
        {selectEasyCallouts(data).map(
          ({ title, description: { description } }) => (
            <aside className={styles.easyCallout} key={title}>
              <h2>{ReactHtmlParser(title)}</h2>
              {fromMarkup(description)}
            </aside>
          )
        )}
      </section>
      {/* banner */}
      <section
        className={styles.banner}
        style={{ backgroundImage: `url(${selectBannerImage(data)})` }}
      >
        <div className={styles.bannerCaptionWrapper}>
          <div className={styles.bannerCaption}>
            {selectBannerCaption(data)}
          </div>
        </div>
      </section>
      {/* icon callouts  */}
      <section className={styles.iconCallouts}>
        {selectFromMarkup(selectIconCalloutTitle)(data)}
        {selectIconCallouts(data).map(
          ({ title, icon, description: { description } }) => (
            <article className={styles.iconCallout} key={icon}>
              <img
                alt={iconMap[icon]}
                className={styles.iconCalloutIcon}
                src={iconMap[icon]}
              />
              <h2>{title}</h2>
              {fromMarkup(description)}
            </article>
          )
        )}
      </section>
      {/* app ad */}
      <section className={styles.appAdd}>
        <div className={styles.appAddInner}>
          <img
            alt=""
            className={styles.appAddImage}
            src={selectAppAddImage(data)}
          />
          {selectFromMarkup(selectAppAddTitle)(data)}
          <h3 className={styles.appAddSubtitle}>
            {selectAppAddSubtitle(data)}
          </h3>
          <ul className={styles.appAddList}>
            {selectAppAddBullets(data).map(bullet => (
              <li className={styles.appAddListItem} key={bullet}>
                <img alt="Blue Check Mark" src={blueCheck} />
                {bullet}
              </li>
            ))}
          </ul>
          <Button
            className={styles.appAddCta}
            label="Download App"
            href={appLink}
          />
        </div>
      </section>
      {/* store ad */}
      <section className={styles.storeAdd}>
        <div className={styles.storeAddBanner}>
          <figure
            style={{ backgroundImage: `url(${selectStoreAddImage(data)})` }}
            className={styles.storeAddBannerImage}
          >
            <h2>{selectStoreAddTitle(data)}</h2>
          </figure>
        </div>
        <div className={styles.storeAddDescription}>
          {selectFromMarkup(selectStoreAddDescription)(data)}
        </div>
        <ul className={styles.storeAddList}>
          {selectStoreAddBullets(data).map(bullet => (
            <li className={styles.storeAddListItem} key={bullet}>
              <img alt="Oragne Check Mark" src={orangeCheck} />
              {bullet}
            </li>
          ))}
        </ul>
        <img
          alt=""
          className={styles.storeAddImageFlip}
          src={selectStoreAddImage(data)}
        />
      </section>
    </Layout>
  )
}

export default withHelmet(homepageSelector)(IndexPage)

export const query = graphql`
  query HomepageQuery {
    site {
      siteMetadata {
        appLink
        payomaticLocationsLink
      }
    }
    contentfulHomepage {
      fields {
        jsonLd
      }
      seoFields {
        title
        description {
          description
        }
        pageTitle
        ogImage {
          file {
            url
          }
        }
      }
      node_locale
      title {
        title
      }
      hero {
        file {
          url
        }
      }
      cardCallout {
        cardCallout
      }
      easyCallouts {
        title
        description {
          description
        }
      }
      bannerCaption
      bannerImage {
        file {
          url
        }
      }
      iconCalloutTitle {
        iconCalloutTitle
      }
      iconCallouts {
        title
        icon
        description {
          description
        }
      }
      appAddTitle {
        appAddTitle
      }
      appAddSubtitle
      appAddImage {
        file {
          url
        }
      }
      appAddBullets
      storeAddTitle
      storeAddDescription {
        storeAddDescription
      }
      storeAddImage {
        file {
          url
        }
      }
      storeAddBullets
    }
  }
`
