module.exports = {
  siteMetadata: {
    title: 'inPower',
    payomaticLocationsLink: 'https://get.inpowercard.com/getstarted/',
    appLink: 'https://payomatic.link/mobile-inpower',
    siteUrl: 'https://www.inpowercard.com'
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-sass',
    {
      resolve: 'gatsby-source-contentful',
      options: {
        spaceId: 'h4yb48fapuxe',
        accessToken:
          'b51f7c2263ad8ce7193974dc12ecce7e290bfbdbd9d0cd54c3b3544891aceb78'
      }
    }
  ]
}
